@import "./../../scss/variables";
@import "./../../scss/mixins";
.primaryServices {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $creme;
  h2 {
    @include font-title;
    color: $greenBeen;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .primaryServicesWrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

