@import "~bootstrap/scss/bootstrap";
@import "scss/variables";
@import "scss/mixins";
@import "scss/utilities";

html {
  font-size: 62.5%;
}

@media screen and (max-width: 1440px){
  html {
    font-size: 50%;
  }
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  font-family: $font;
  position: relative;
  overflow-x: hidden;
}

p {
  font-size: 2rem;
  font-weight: lighter;
  line-height: 1.8em;
  margin-bottom: 2rem;
}

ul, li {
  font-size: 1.8rem;
  font-weight: lighter;
  line-height: 1.8em;
}

.modal-body {
  padding: 0;
  margin: 0;
}

.fade.modal.show {
  background-color: rgba($black, .7);
  backdrop-filter: blur(10px);
}

.carousel-indicators li {
  background-color: $primary-red;
}

.dropdown-item.active {
  background-color: transparent;
}

.dropdown-menu {
  background-color: $secondary-red;
  border: none;
  box-shadow: -2px 11px 33px -6px rgba(66, 68, 90, 1);

  @media only screen and (max-width: 991px) {
    box-shadow: none;
  }
}