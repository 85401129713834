// colors by Iwona
$crepe: #F1D3BC;
$butterScotch: #FDD475;
$butter: #FFEB94;
$redPepper: #C60000;
$redDelicious: #A11F0C;
$peerGreen: #739f3D;
$grannySmith: #BBCF4A;
$greenBeen: #68A225;
$leafyGreen: #7CAA2D;

// ours
//$primary-green: #4DAB77;
//$secondary-green: #cfd564;
//$tertiary-green: #eef0a9;
//$primary-red: #d75b66;
//$white: #fcfdfe;
//$black: #192e5b;

$green: #5e751d;
$light-green: #a6bf46;
$primary-green: $leafyGreen;
$secondary-green: $grannySmith;
$tertiary-green: $butter;
$primary-red: $redPepper;
$secondary-red: $redDelicious;
$white: #fcfdfe;
$black: #000000;

$manager-pink: #e91e63;


$grey-background: #f1f1f1;
$creme: rgba(#fff6af, .4);

$font: 'Open Sans', sans-serif;

$max-width: 130rem;

$carousel-transition: transform 2s ease, opacity .5s ease-out;
$box-shadow: 0 4px 6px 0 rgba($black, .3);
