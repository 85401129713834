@import "src/scss/variables";
@import "src/scss/mixins";

.navbar {
  background-color: $secondary-red;
  padding: 1.5rem 0;
  @include container-position;

  &Wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;

    .logo {
      align-self: center;
      justify-self: center;
      width: 25rem;
      height: auto;
    }

    .toggle {
      & span {
        width: 4rem;
        height: 4rem;
      }

      width: 6rem;
      height: 6rem;
    }

    &Items {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      font-size: 2rem;
      list-style: none;
      margin: 0;
      padding: 0;

      & > div > div {
        & > a {
          @include hoverableNavItem;
        }
      }

      & > div > a {
        padding: 1rem;
        text-align: center;
        @include hoverableNavItem;
      }

      & > a {
        @include hoverableNavItem;
      }

      @media (max-width: 991px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &Item {
        color: $white;
        cursor: pointer;
        transition: all .4s;
        font-weight: normal;

        &:not(:last-child) {
          margin-right: 2rem;
        }

        &:hover {
          text-decoration: none;
          color: $butterScotch;
        }

        @media (max-width: 991px) {
          margin: 1rem;
          font-size: 2.2rem;
        }

        &Dropdown {
          font-size: 2rem;
          padding: 1rem 2rem;
        }
      }
    }
  }
}

header {
  @include container-position;
  background-color: $secondary-red;

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navigationItems {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: flex-end;
      list-style: none;
      margin: 0;
      padding: 0;

      .navigationItem {
        font-size: 2rem;
        cursor: pointer;
        color: $white;
        font-weight: lighter;
        transition: all .4s;

        &:not(:last-child) {
          margin-right: 2rem;
        }

        &:hover {
          color: $butterScotch;
        }
      }
    }

    .logo {
      align-self: center;
      justify-self: center;
      width: 25rem;
      height: auto;
    }
  }
}






