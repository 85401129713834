@import "../../scss/variables";
.modal {
  display: flex;

  &Content {
    background-color: $white;
    width: 80%;
    max-width: 120rem;
    display: flex;
    flex-grow: 1;
    &Img {
      padding: 0;
      img {
        width: 30rem;
        height: 100%;
        object-fit: cover;
      }
    }
    &Info {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-size: 3rem;
        text-transform: uppercase;
        background-image: linear-gradient(to right, darken($primary-red, 20%), $primary-red);
        color: transparent;
        -webkit-background-clip: text;
        padding-bottom: .5rem;
      }
      h4 {
        font-size: 1.8rem;
        text-transform: uppercase;
        padding-bottom: 2rem;
      }
      & > div {
        column-count: 2;
        column-gap: 4rem;
        column-rule: 1px solid $primary-red;
        font-size: 1.8rem;
        font-weight: normal;
      }
    }
  }
}

