@import "src/scss/variables";
@import "src/scss/mixins";

.newsItem {
  display: flex;
  flex-direction: column;
  width: 40rem;
  border-radius: .5rem;
  background-color: $white;
  box-shadow: 0 6px 18px 0 rgba(199,195,199,1);
  text-align: left;
  margin-bottom: 5rem;

  @media only screen and (max-width: 900px) {
    margin-bottom: 5rem;
  }

  & > img {
    width: 100%;
  }

  &Content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    & > h3 {
      font-weight: bolder;
      margin-bottom: 1.5rem;
    }
    & > div {
      font-size: 1.7rem;
      line-height: 1.5em;
      padding-bottom: 1.5rem;
    }

    & > button {
      max-width: 18rem;
    }
    padding: 3rem;
  }
}
