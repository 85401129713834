@import "../../scss/variables";

.chevron {
  font-size: 4rem;
  cursor: pointer;
  text-align: left;
  @media only screen and (max-width: 900px) {
    display: none;
  }

  .topMargin {
    margin-top: 4rem;
  }
  .noOutline {
    outline: none;
    background-color: $primary-red;
    &:hover {
      background-color: darken($primary-red, 10%);
    }
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
