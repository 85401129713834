@import "./src/scss/variables";
@import "./src/scss/mixins";

.employees {
  background-color: $creme;

  &Container {
    .title {
      display: flex;
      justify-content: center;

      h2 {
        @include font-title;
        color: $greenBeen;
        text-align: center;
        margin-bottom: 5rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    &Wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media screen and (max-width: 890px) {
        flex-direction: column;
      }
    }
  }
}


