@import 'src/scss/variables';
@import 'src/scss/mixins';

footer {
  padding: 3rem 0 3rem 0;
  background-color: $secondary-red;
  color: $white;
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &Wrapper{
      padding-bottom: 3rem;
      border-bottom: 2px solid $white;
      &Logo {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &Locations {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        img {
          padding-bottom: 2rem;
        }
        .location {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          &:not(:last-child) {
            margin-bottom: 2rem;
          }
          h3 {
            font-size: 2rem;
            font-weight: bold;
          }
          span {
            font-size: 1.4rem;
          }
        }
      }
    }
    .copyrights {
      text-align: center;
      color: $white;
      padding-top: 2rem;

    }
  }

  .goBack {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }
}
