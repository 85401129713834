@import 'src/scss/variables';
@import 'src/scss/mixins';

.person {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 16px 0 rgba($black, .7);
    }
  }
  h3 {
    color: $secondary-red;
    margin-top: 2rem;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }
  h4 {
    text-align: center;
    font-style: italic;
    font-size: 1.7rem;
  }
}
