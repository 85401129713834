@import "./../../scss/variables";

.primaryService {
  width: 30rem;
  background-color: $light-green;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $box-shadow;
  margin-bottom: 5rem;
  padding: 4rem 0;
  cursor: pointer;
  transition: all .5s;
  position: relative;

  & > img {
    height: 12rem;
    width: auto;
  }

  & > p, ul {
    display: none;
  }

  h3 {
    margin-top: 1.5rem;
    font-weight: bold;
    color: $white;
    text-transform: uppercase;
    text-align: center;
  }
  &:hover {
    box-shadow: 0 6px 18px 0 rgba($black, .5);
  }
}
