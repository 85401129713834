@import "variables";

@mixin container-position {
  display: flex;
  flex-direction: column;
  align-items: center;

}

@mixin primary-red-gradient {
  border-image-slice: 1;
  border-image-source: linear-gradient(285deg, rgba($secondary-red, .8) 25%, $primary-red 75%);
}

@mixin font-title {
  display: inline-flex;
  font-size: 5rem;
  margin-bottom: 2rem;
  padding-bottom: .8rem;
  border-bottom: 3px solid;
  @include primary-red-gradient;
  @media screen and (max-width: 320px) {
    font-size: 3rem;
  }
}

@mixin hoverableNavItem {
  color: white!important;
  font-weight: 200;
  &:hover {
    text-decoration: none;
    color: $butterScotch!important;
    background-color: transparent!important;
  }
}