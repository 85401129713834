@import "./../../scss/variables";

.primaryServiceContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem;

  .containerHeading {
    display: flex;
    justify-content: flex-start;

    h2 {
      //text-align: center;
      font-size: 3.5rem;
      font-weight: bold;
      text-transform: uppercase;
      color: $primary-green;
    }

    .svg {
      width: 4rem;
      height: 4rem;
    }
  }

  .containerBody {
    display: flex;

    .containerContent {
      font-size: 2rem;
      ul {
        display: flex;
        flex-direction: column;
        li {
          list-style: none;
          display: flex;
          flex-grow: 1;
          align-items: center;
          font-weight: normal;

          .icon {
            height: 2.5rem;
            width: 2.5rem;
            fill: $primary-green;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
