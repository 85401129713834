@import "src/scss/variables";
@import "src/scss/mixins";

:export {
  primaryGreen: rgba($primary-green, .5)
}

;

$transition: 23rem;

.site {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .justify {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }

  .bigLogo {
    height: 30rem;
  }

  &Body {
    padding-top: 3rem;
    padding-bottom: 4rem;

    h2 {
      @include font-title;
      color: $white;
      margin-bottom: 4rem;
    }

    h3 {
      font-size: 3rem;
      color: $white;
    }
    p {
      color: $white;
    }
  }
}
