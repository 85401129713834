@import "../../scss/variables";

.primaryButton {
  padding: 1.2rem 4.5rem;
  font-size: 1.8rem;
  background-image: linear-gradient(120deg, $secondary-red 25%, $primary-red 75%);
  background-position: 50%;
  background-size: 200% 100%;
  background-color: transparent;
  border: none;
  color: $white;
  cursor: pointer;
  transition: background-position .1s ease-out;
  &:hover {
    background-position: 10%;
    background-color: unset;

  }
}
