@import 'src/scss/variables';
@import 'src/scss/mixins';

.services {
  background-color: $creme;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    @include font-title;
    color: $greenBeen;
    text-align: center;
    margin-bottom: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  h3 {
    color: $greenBeen;
    font-size: 2.5rem;
  }
  &Wrapper {
    display: flex;
    justify-content: space-around;
    padding-bottom: 3rem;
    @media screen and (max-width: 890px) {
      flex-direction: column;
      align-items: center;
    }

    .service {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 35rem;
      @media screen and (max-width: 890px) {
        margin-bottom: 3rem;
      }

        svg {
        width: 5rem;
        height: 5rem;
        fill: $white;
        margin-right: 1rem;
      }
    }
  }
}
